import React, { useState } from 'react';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


 

  return (
    <nav className="navbar">
      <div className="navbar-logo">
      <div class="animated-heading">Lingstitute</div>
      <div class="sub-heading">The Language Community</div>
      
       
      </div>
      <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="/home">Home</a></li>
          <li><a href="/about">About</a></li>
          
          <li><a href="/contact">Contact</a></li>
          <li><a  href='/inform' style={{cursor:'pointer'}}><i class="fa-solid fa-award"></i>&nbsp;Enroll</a></li>
        </ul>
      </div>
      
      <div className="hamburger" onClick={toggleMenu}>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
      </div>
    </nav>
  );
};

export default Navbar;
