import React from 'react';
  // Custom styles

  const handleWhatsAppClick = () => {
    // Replace with your WhatsApp number and message
    const phoneNumber = "919492022599"; // Your WhatsApp number (with country code, no spaces or + symbol)
    const message = "Hello! I am intrested to Register Here for Lingstitute the language community"; // Predefined message

    // WhatsApp URL
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Open WhatsApp chat in a new tab
    window.open(whatsappURL, '_blank');
  };
const Inform= () => {



   
  const communityFeatures = [
    {
      title: "Course Validity",
      description: "Enroll in courses with flexible timelines and access them anytime within the course validity period.",
      imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBhngha9UrDXH1B1oWXofis7FP-elb3BOCkRa40urnA56reoIXStNf2x9L75ZbbmF4XcQ&usqp=CAU", // Replace with actual image URL
    },
    {
      title: "Complete Grammar Classes",
      description: "Master English grammar with comprehensive, easy-to-follow grammar classes covering all key topics.",
      imageUrl: "https://centre4english.in/wp-content/uploads/2023/07/grammer-course.jpg",
    },
    {
      title: "Study Material",
      description: "Get access to a wide range of study materials, including textbooks, worksheets, and practice exercises.",
      imageUrl: "https://www.thinkiit.in/wp-content/uploads/2022/09/NTSE-Study-material.webp",
    },
    {
      title: "Vocabulary Builder",
      description: "Expand your vocabulary with daily word lists, quizzes, and real-life usage examples.",
      imageUrl: "https://m.media-amazon.com/images/I/81ev7jrtMQL._AC_UF1000,1000_QL80_.jpg",
    },
    {
      title: "Practice Questions",
      description: "Enhance your skills with a variety of practice questions to assess your learning progress.",
      imageUrl: "https://d4804za1f1gw.cloudfront.net/wp-content/uploads/sites/56/2018/03/ACT-Practice-Test-Card-Mar18-300x300.png",
    },
  ];

  const softSkills = [
    {
      title: "Debate",
      description: "Participate in debates to improve critical thinking, argument formulation, and communication skills.",
      imageUrl: "https://www.harrowappi.jp/wp-content/uploads/2024/05/debate-club-01-min.jpg",
    },
    {
      title: "Discussion",
      description: "Engage in discussions to enhance your ability to express ideas clearly and listen actively.",
      imageUrl: "https://www.factsmostly.com/wp-content/uploads/2024/06/Group-Discussion.webp",
    },
    {
      title: "Business Communication",
      description: "Learn professional communication skills to succeed in a corporate environment.",
      imageUrl: "https://static.archerdxp.io/cms/e35ad273-4426-4484-b090-0647b5ee4aa5.jpg",
    },
    {
      title: "Presentation Skills",
      description: "Master the art of delivering impactful presentations with confidence.",
      imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx1hVvv5aUuaQw_mFSPaYIiThBtmaldJVfJm4kUJAFDVbULIbbhF9AftcNy8lh8a1389w&usqp=CAU",
    },
    {
      title: "Interactive Skills",
      description: "Develop interpersonal skills and improve your ability to interact effectively with others.",
      imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfVbepcCsstSqp7cI9ZEWxyazGqgaeUlendg&s",
    },
    {
      title: "Learn Microsoft Tools",
      description: "Gain proficiency in essential Microsoft Office tools such as Word, Excel, and PowerPoint.",
      imageUrl: "https://techcommunity.microsoft.com/t5/image/serverpage/image-id/518832iF4686AAB4DBEEC9B/image-size/original?v=v2&px=-1",
    },
    {
      title: "AI Tools to Boost Productivity",
      description: "Gain proficiency in essential Microsoft Office tools such as Word, Excel, and PowerPoint.",
      imageUrl: "https://www.managedoutsource.com/wp-content/uploads/2021/11/top-5-ai-tools-for-business.jpg",
      
    },
    {
      title: "Interactive Games to Build Networking",
      description: "Gain proficiency in essential Microsoft Office tools such as Word, Excel, and PowerPoint.",
      imageUrl:"https://blogimage.vantagecircle.com/content/images/2024/04/Problem-solving-activities.webp",
    },

  ];


 


  return (
    <div className="inform-container">
      <h1 className="inform-title">Explore Our English Language and Skill Development Features</h1>
      <p className="inform-intro">
        Join us and take advantage of our language learning resources and soft skills training for a successful career.
      </p>

      <div className="features-grid">
        {communityFeatures.map((feature, index) => (
          <div key={index} className="feature-card">
            <img src={feature.imageUrl} alt={feature.title} className="feature-image" />
            <h2 className="feature-title">{feature.title}</h2>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>

      <h2 className="soft-skills-title">Soft Skills for Interviews and Career Growth</h2>
      <div className="soft-skills-grid">
        {softSkills.map((skill, index) => (
          <div key={index} className="soft-skill-card">
            <img src={skill.imageUrl} alt={skill.title} className="soft-skill-image" />
            <h3 className="soft-skill-title">{skill.title}</h3>
            <p className="soft-skill-description">{skill.description}</p>
          </div>
        ))}
        
      </div>
      <br></br>
      <div class="button-container">
       <marquee><h2>New Batch Will Start from November 1st 2024 !! Click Below to Register!!</h2></marquee> 
        <br></br>
        <br></br>
        <a onClick={handleWhatsAppClick} class="custom-button"  style={{cursor:'pointer'}}>Register</a>
    </div>
    </div>
  );
};

export default Inform;
